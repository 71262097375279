<template>
  <v-container class="p-4">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-3">
        Matrices de aprobación
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="mt-1"> </v-row>
    <p class="text-right mt-5">
      Número de matrices total:
      {{ filteredApprovalsMatrix.length }}
    </p>
    <v-data-table
      class="elevation-1 px-5 py-3 mt-1"
      :headers="tableHeaders"
      :items="filteredApprovalsMatrix"
      :items-per-page="25"
      :sort-by="sortBy"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
      :sort-desc="sortDesc"
      :loading="loading"
      loading-text="Cargando matrices"
      item-key="id"
      no-data-text="Sin matrices"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat class="w-50 pt-5">
          <v-text-field
            label="Busqueda"
            v-model="filters.filter"
            class="mb-5"
            clearable
            placeholder="Buscar matriz"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalUpdateOrCreateApprovalMatrix(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.management="{ item }">
        <span>
          {{
            item.management
              ? `${item.employee_category.name} (${item.management.name})`
              : "-"
          }}
        </span>
      </template>
      <template v-slot:item.max_amount="{ item }">
        <span>
          {{
            item.max_amount
              ? `$ ${item.max_amount.toLocaleString("es", optionsFormatting)}`
              : "-"
          }}
        </span>
      </template>
    </v-data-table>
    <update-approval-matrix
      :modalAction="dialogUpdateOrCreateApprovalMatrix"
      :closeModalApprovalMatrix="closeModalUpdateOrCreateApprovalMatrix"
      :currentApprovalMatrix="currentApprovalMatrix"
      @approveMatrixUpdated="getApprovalsMatrixData"
    ></update-approval-matrix>
  </v-container>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { mapActions, mapGetters } from "vuex";
import UpdateApprovalMatrix from "./UpdateApprovalMatrix.vue";
import config from "@/config";

export default {
  components: { UpdateApprovalMatrix },
  watch: {
    "filters.page": function(page) {
      this.$emit("changePage", page);
    },
  },
  mounted() {
    this.getApprovalsMatrixData();
  },
  data() {
    return {
      optionsFormatting: { style: "decimal", useGrouping: true },
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Matriz de aprobación",
          disabled: true,
          href: "/mantenedores/matrizAprobacion",
        },
      ],
      dialogUpdateOrCreateApprovalMatrix: false,
      currentApprovalMatrix: null,
      filters: {
        filter: "",
      },
      pageCount: 0,
      sortBy: ['business_unit.name', 'max_amount'],
      sortDesc: [true, true],
      tableHeaders: [
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: "Unidad de Negocio",
          align: "center",
          sortable: true,
          value: "business_unit.name",
        },
        {
          text: "Categoria",
          align: "center",
          sortable: false,
          value: "employee_category.name",
        },
        {
          text: "Monto Máximo",
          align: "center",
          sortable: true,
          value: "max_amount",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getApprovalsMatrix: "maintainers/getApprovalsMatrix",
    }),
    async getApprovalsMatrixData() {
      await this.getApprovalsMatrix({
        password: config.password_manteiners,
      });
    },
    openModalUpdateOrCreateApprovalMatrix(approvalMatrix) {
      this.currentApprovalMatrix = approvalMatrix;
      this.dialogUpdateOrCreateApprovalMatrix = true;
    },
    closeModalUpdateOrCreateApprovalMatrix($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentApprovalMatrix = null;
        this.dialogUpdateOrCreateApprovalMatrix = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: "maintainers/loading",
      approvalsMatrix: "maintainers/approvalsMatrix",
    }),
    filteredApprovalsMatrix() {
      if (this.filters.filter) {
        let filter = this.filters.filter.trim().toLowerCase();
        return this.approvalsMatrix.filter((approvalMatrix) => {
          return (
            approvalMatrix.employee_category.name
              .toLowerCase()
              .includes(filter) ||
            approvalMatrix.max_amount
              .toString()
              .toLowerCase()
              .includes(filter)
          );
        });
      }
      return this.approvalsMatrix;
    },
  },
};
</script>

<style></style>
