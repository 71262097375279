var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-4"},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-3"},[_vm._v(" Matrices de aprobación ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb}})],1),_c('v-row',{staticClass:"mt-1",attrs:{"align-content":"center","align-md":"center"}}),_c('p',{staticClass:"text-right mt-5"},[_vm._v(" Número de matrices total: "+_vm._s(_vm.filteredApprovalsMatrix.length)+" ")]),_c('v-data-table',{staticClass:"elevation-1 px-5 py-3 mt-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredApprovalsMatrix,"items-per-page":25,"sort-by":_vm.sortBy,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"sort-desc":_vm.sortDesc,"loading":_vm.loading,"loading-text":"Cargando matrices","item-key":"id","no-data-text":"Sin matrices"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"w-50 pt-5",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"Busqueda","clearable":"","placeholder":"Buscar matriz","append-icon":"mdi-magnify"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openModalUpdateOrCreateApprovalMatrix(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)]}},{key:"item.management",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.management ? ((item.employee_category.name) + " (" + (item.management.name) + ")") : "-")+" ")])]}},{key:"item.max_amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.max_amount ? ("$ " + (item.max_amount.toLocaleString("es", _vm.optionsFormatting))) : "-")+" ")])]}}])}),_c('update-approval-matrix',{attrs:{"modalAction":_vm.dialogUpdateOrCreateApprovalMatrix,"closeModalApprovalMatrix":_vm.closeModalUpdateOrCreateApprovalMatrix,"currentApprovalMatrix":_vm.currentApprovalMatrix},on:{"approveMatrixUpdated":_vm.getApprovalsMatrixData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }