<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="900px"
      scrollable
      @keydown="executeCloseUpdateApprovalMatrix"
      @click:outside="executeCloseUpdateApprovalMatrix"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            Actualizar matriz de aprobación de gerencia
            {{
              currentApprovalMatrix
                ? `${currentApprovalMatrix.employee_category.name} (${currentApprovalMatrix.business_unit.name})`
                : "Cargando"
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form @submit.prevent="updateApprovalMatrix" class="my-5">
            <v-row align-content="center" justify="center">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  type="number"
                  :min="1"
                  class="py-5"
                  v-model="form.max_amount"
                  hide-details
                  label="Ingresa el monto máximo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            class="text-white"
            @click="updateApprovalMatrix"
          >
            <small>
              Actualizar montos
            </small>
          </v-btn>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseUpdateApprovalMatrix"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import config from "@/config";
import { mapActions } from "vuex";
import swal from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      required: true,
    },
    closeModalApprovalMatrix: {
      type: Function,
      default: () => {},
    },
    currentApprovalMatrix: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        max_amount: null,
      },
    };
  },
  methods: {
    ...mapActions({
      sendUpdateApprovalMatrix: "maintainers/updateApprovalMatrix",
    }),
    executeCloseUpdateApprovalMatrix($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalApprovalMatrix($event);
      }
    },
    resetForm() {
      this.form.max_amount = null;
    },
    async updateApprovalMatrix() {
      if (!this.form.max_amount) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "El monto máximo es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      const response = await this.sendUpdateApprovalMatrix({
        password: config.password_manteiners,
        approval_matrix_id: this.currentApprovalMatrix.id,
        max_amount: this.form.max_amount,
      });
      if (response.status == 200) {
        swal.fire({
          icon: "success",
          title: "Excelente",
          text: `Matriz de aprobación actualizada con éxito`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        this.resetForm();
        this.$emit("approveMatrixUpdated");
        this.closeModalApprovalMatrix(true);
      }
      if (response.status == 500) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    setCurrentApprovalMatrix(approvalMatrix) {
      this.form.max_amount = approvalMatrix.max_amount;
    },
  },
  watch: {
    currentApprovalMatrix: {
      handler(newApprovalMatrix) {
        if (newApprovalMatrix) {
          this.setCurrentApprovalMatrix(newApprovalMatrix);
        } else {
          this.resetForm();
        }
      },
    },
  },
};
</script>

<style></style>
